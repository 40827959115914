import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ProctorService } from './proctor.service';

@Injectable({
  providedIn: 'root'
})
export class AiProctorService {

  stopped: boolean;
  private video: HTMLVideoElement;
  private videoStream: MediaStream;
  private canvas: HTMLCanvasElement;
  private _aiImageAnalysisTimeout: any;
  private _aiNoiseAnalysisTimeout: any;
  private readonly _noiseLevelThreshold: number = 30;
  private audioVisualiserJavascriptNode: ScriptProcessorNode;
  private analyser: AnalyserNode;

  private canFlagForNoise: boolean;

  aiFlagConfig: AIFlagConfig[];

  onImageAnalysis: Subject<any> = new Subject();
  onNoiseAnalysis: Subject<any> = new Subject();

  constructor(private proctorService: ProctorService) {
    this.video = document.createElement('video');
    this.video.autoplay = true;
    this.video.muted = true;
    this.video.style.display = 'none';
    this.video.width = 240;
    this.video.height = 240;
    this.canvas = document.createElement("canvas");
  }

  private async startVideo(videostream: MediaStream) {
    this.video.srcObject = videostream;
    await this.video.play();
  }

  /**
  * Initialize and start the AI proctor.
  * @param faceSnapCount number of candidate's facial data to keep in memory
  * @param faceSnapInterval interval between each snapshot in milliseconds (ms)
  * @param candidateFaceData candidate's image
  */
  async startAiProctor(videoStream: MediaStream) {
    this.stopped = false;
    this.canFlagForNoise = true;

    await this.startAIProctorVideo(videoStream);

    //this.aiFlagConfig = await this.proctorService.getAIFlagConfig();

    const aiAnalysis = async () => {
      try {
        if (!this.stopped) {
          const data = this.takeVideoSnapshot(this.video, this.canvas);
          const analysis = await this.proctorService.getAiImageAnalysis(data) as any;
          this.onImageAnalysis.next(analysis);
        }
      }
      finally {
        if (!this.stopped) {
          this._aiImageAnalysisTimeout = setTimeout(aiAnalysis, 10 * 1000);
        }
      }
    };

    this.startNoiseAnalysis(this.videoStream);
    this._aiImageAnalysisTimeout = setTimeout(aiAnalysis, 1000);
    console.log("Ai-Proctor started")
  }

  public async startAIProctorVideo(videoStream: MediaStream) {
    this.videoStream = videoStream;
    await this.startVideo(this.videoStream);
    this.canvas.width = this.video.videoWidth;
    this.canvas.height = this.video.videoHeight;
  }

  stopAiProctor() {
    try {
      clearTimeout(this._aiImageAnalysisTimeout);
      clearTimeout(this._aiNoiseAnalysisTimeout);

      this.stopped = true;
      this.canFlagForNoise = false;
      this.onImageAnalysis = new Subject();
      this.onNoiseAnalysis = new Subject();
      this.audioVisualiserJavascriptNode?.removeEventListener("audioprocess", this.getNoiseLevel);
    }
    finally {
      this.video.pause();
      this.video.srcObject = null;
      this.videoStream = null;
      this.audioVisualiserJavascriptNode = null;
      console.log("Ai-Proctor stopped")
    }
  }

  takeVideoSnapshot(video, canvas) {
    let ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(video, 0, 0);
    return canvas.toDataURL('image/jpeg');
  }

  private startNoiseAnalysis(stream: MediaStream) {
    const audioContext = new AudioContext();
    this.analyser = audioContext.createAnalyser();

    const microphone = audioContext.createMediaStreamSource(stream);
    this.audioVisualiserJavascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

    this.analyser.smoothingTimeConstant = 0.8;
    this.analyser.fftSize = 1024;

    microphone.connect(this.analyser);
    this.analyser.connect(this.audioVisualiserJavascriptNode);
    this.audioVisualiserJavascriptNode.connect(audioContext.destination);

    this.audioVisualiserJavascriptNode.addEventListener("audioprocess", this.getNoiseLevel);
  }

  getNoiseLevel = () => {
    var array = new Uint8Array(this.analyser?.frequencyBinCount);
    this.analyser.getByteFrequencyData(array);
    let values = 0;

    let length = array.length;
    for (var i = 0; i < length; i++) {
      values += (array[i]);
    }

    let noiseLevel = values / length;
    if (this.canFlagForNoise && noiseLevel > this._noiseLevelThreshold) {
      this.canFlagForNoise = false;
      this.onNoiseAnalysis.next(noiseLevel);
      console.log(noiseLevel);
      if (!this.stopped) {
        this._aiNoiseAnalysisTimeout = setTimeout(this.resetCanFlagForNoise, 30 * 1000);
      }
    }
  }

  resetCanFlagForNoise = () => {
    if (!this.stopped) {
      this.canFlagForNoise = true;
      console.log('resCanFlag called');
    }
  };

  private async getImageAnalysis(donotsendflagstoserver = false, force: boolean = false) {
    if (this.stopped && !force) {
      throw new Error('AI proctor service is not running');
    }

    const data = this.takeVideoSnapshot(this.video, this.canvas);
    return await this.proctorService.getAiImageAnalysis(data, donotsendflagstoserver) as any;
  }

  async aiPreventExamStart(force: boolean = false): Promise<AIPreventExamStartResponse> {
    const res: AIPreventExamStartResponse = {
      reasons: [],
      preventstart: false
    };
    // const preventStartFlags: Set<AIFlags> = new Set();

    // this.aiFlagConfig.forEach(flag => {
    //   if(flag.preventstart){
    //     preventStartFlags.add(flag.aiflag);
    //   }
    // });

    //all anomalous conditions should prevent starting
    try {
      const { brightnessAnalysis, faceAnalysis } = await this.getImageAnalysis(true, force);

      // if(brightnessAnalysis == 'low'){// && preventStartFlags.has(AIFlags.LowLight)){      
      //   res.reasons.push("Low light detected");
      // }
      // else if(brightnessAnalysis == 'high'){// && preventStartFlags.has(AIFlags.HighIntensity)){
      //   res.reasons.push("High intensity light detected");
      // }

      if (faceAnalysis.noFace) {// && preventStartFlags.has(AIFlags.NoFace)){
        res.reasons.push("No face detected");
      }
      else {
        if (faceAnalysis.multipleFaces) {// && preventStartFlags.has(AIFlags.MultipleFaces)){
          res.reasons.push("Multiple faces detected");
        }
        if (faceAnalysis.candidateFaceIsNotAMatch) {// && preventStartFlags.has(AIFlags.FaceMismatch)){
          res.reasons.push("Candidate face mismatch detected");
        }
      }
      res.preventstart = res.reasons.length > 0;
    }
    catch (error) {
      console.error('Error while checking aiPreventExamStart');
      console.error(error);
    }
    return res;
  }
}

export interface AIFlagConfig {
  examid: string,
  company: string,
  threshold: number,
  preventstart: boolean,
  minutestoreduce: number,
  maxnoofrestarts: number,
  aiflag: AIFlags,
  penalty: string
}

export interface AIPreventExamStartResponse {
  reasons: string[];
  preventstart: boolean;
}

export enum AIFlags {
  LowLight = 'Lowlight',
  MultipleFaces = 'MultipleFaces',
  NoFace = 'NoFace',
  HighIntensity = 'HighIntensity',
  FaceMismatch = 'FaceMismatch',
  Noise = 'Noise'
}