import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-examiframe',
  templateUrl: './examiframe.component.html',
  styleUrls: ['./examiframe.component.css']
})
export class ExamiframeComponent implements OnInit {

  @ViewChild('examIframe') examIframe!: ElementRef;
  constructor() { }

  ngOnInit(): void {
  }

}
