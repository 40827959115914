<div class="modal" data-backdrop="static" tabindex="-1" role="dialog" id="chatModal" #chatModal>
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Chat (Your Proctor)</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" #chatModalBody>
                <div class="chat-modal-chat">
                    <div class="chat-modal-chat-inner" id="chatcontainer">
                        <div *ngFor="let chatline of chat" class="chat-modal-chat-line" [ngClass]="{local: chatline.local, remote: !chatline.local}">
                            <span [ngClass]="{'chat-you-span': chatline.local, 'chat-proctor-span': !chatline.local}"><span *ngIf="chatline.local">You: </span><span *ngIf="!chatline.local">Proctor: </span></span>
                            <span [innerHTML]="chatline.message"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div>
                    <textarea style="width: 100%; padding: 3px" (keydown.enter)="sendChatMessage($event)" #chattextarea [disabled]="!this.chatOpened"></textarea>
                </div>
                <div class="sendbuttondiv">
                    <button (click)="sendChatMessage(null)" class="btn btn-primary">Send</button>
                </div>
            </div>
        </div>
    </div>
</div>
