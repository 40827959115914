import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProctorService } from '../services/proctor.service';
import { UtilService } from '../services/util.service';

declare const $: any;
declare const MediaRecorder: any;
// let procteeVideo:any;
// let videostream:any;
// declare var MediaRecorder: any;

@Component({
  selector: 'app-procteevideomodal',
  templateUrl: './procteevideomodal.component.html',
  styleUrls: ['./procteevideomodal.component.css']
})

export class ProcteevideomodalComponent implements OnInit, OnDestroy {

  micRes = false;
  audRes = false;
  volRes = false;
  
  @ViewChild('micTestAudio') audioPlayer!: ElementRef;
  @ViewChild('micTestResponse') micTestRes!: ElementRef;
  @ViewChild('micTestResponseDiv') micTestResDiv!: ElementRef;
  @ViewChild('micTestBtn') recBtn!: ElementRef
  @ViewChild('procteeAudio') procteeAudio!: ElementRef;
  @ViewChild('procteeVideo') procteeVideo!: ElementRef;
  @ViewChild('audioTestResponse') audioTestResponse!: ElementRef;
  @ViewChild('micTestResponse') micTestResponse!: ElementRef;
  @ViewChild('audioTestResponseDiv') audioTestResponseDiv!: ElementRef;
  @ViewChild('proceedBtn') proceedBtn!: ElementRef;
  @ViewChild('procteeMediaModal') procteeMediaModal!: ElementRef;
  @ViewChild('procteeVideoDiv') procteeVideoDiv!: ElementRef;
  @ViewChild('procteeAudioDiv') procteeAudioDiv!: ElementRef;

  recording = false;
  recorded = false;

  mediaTestStarted = false;

  showVideoTestDiv = false;
  showAudioTestDiv = false;

  audioPlayEnded = false;

  audioVisualiserJavascriptNode: ScriptProcessorNode;

  constructor(private util: UtilService, private proctorService: ProctorService) { }
  ngOnDestroy(): void {
    if(this.audioVisualiserJavascriptNode){
      this.audioVisualiserJavascriptNode.disconnect();
    }
  }

  ngOnInit(): void {
    // window.addEventListener("message", async (evt) => {
    //   console.log(evt.data);
    //   const messageid = evt.data.messageid;
    //   if (!messageid) {
    //     throw 'messageid not set';
    //   }
    //   const ret = { messageid: messageid };

    //   if (evt.data.operation === 'procteetestvideo') {
    //     await this.startProcteeTestVideo();
    //   }
    // });
  }

  startProcteeTestAudio() {
    this.procteeAudio.nativeElement.play();
    this.procteeAudio.nativeElement.onended = () => {
      $(this.audioTestResponseDiv.nativeElement).show();
    };
  }
  
  async startprocteeMicrophoneTest() {
    navigator.mediaDevices.getUserMedia({ audio: true, video: false })
      .then((stream) => {
        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
  
        const microphone = audioContext.createMediaStreamSource(stream);
        this.audioVisualiserJavascriptNode = audioContext.createScriptProcessor(2048, 1, 1);
  
        analyser.smoothingTimeConstant = 0.8;
        analyser.fftSize = 1024;
  
        microphone.connect(analyser);
        analyser.connect(this.audioVisualiserJavascriptNode);
        this.audioVisualiserJavascriptNode.connect(audioContext.destination);

        this.audioVisualiserJavascriptNode.addEventListener("audioprocess", () => {
          var array = new Uint8Array(analyser.frequencyBinCount);
          analyser.getByteFrequencyData(array);
          let values = 0;
  
          let length = array.length;
          for (var i = 0; i < length; i++) {
            values += (array[i]);
          }
  
          let average = values / length;
          this.colorPids(average);
        });
      })
      .catch((err) => {
        /* handle the error */
      });
  }
  
  recordAudio() {
    // let audioPlayer = document.getElementById("micTestAudio");
    // let micTestRes = document.getElementById("micTestResponse");
    // let micTestResDiv = document.getElementById("micTestResponseDiv");
    // let recBtn = document.getElementById("micTestBtn");
    this.recording = true;
    this.recorded = false;
    this.audioPlayEnded = false;
    this.micTestRes.nativeElement.checked = false;

    //this.micTestRes.nativeElement.style.display = "none";
    //this.micTestResDiv.nativeElement.style.display = "none";
    //this.audioPlayer.nativeElement.style.display = "none";
    //this.recBtn.nativeElement.style.backgroundColor = "red";
  
    this.audioPlayer.nativeElement.onended = () => {
      this.audioPlayEnded = true;
      //this.micTestRes.nativeElement.style.display = "block";
      //this.micTestResDiv.nativeElement.style.display = "block";
    };
  
    navigator.mediaDevices.getUserMedia({ audio: true, video: false })
      .then((stream) => {
        const mRecorder = new MediaRecorder(stream);
        mRecorder.start();
        mRecorder.addEventListener("dataavailable", (e: any) => {
          if (e.data.size > 0) {
            let audiodata = new Blob([e.data], { 'type': 'audio/ogg; codecs=opus' });
            this.audioPlayer.nativeElement.src = URL.createObjectURL(audiodata);
          }
        });
  
        setTimeout(() => {
          mRecorder.stop();

          stream.getAudioTracks()[0].stop();

          this.recorded = true;
          this.recording = false;
          
          //recBtn.style.backgroundColor = "#007bff";
          //this.audioPlayer.nativeElement.style.display = "block";

          //mRecorder.start();
        }, 6000);
      })
      .catch();
  }
  
  colorPids(vol: number) {
    if (vol > 3) {
      this.volRes = true;
    }
  
    let all_pids = $('.pid');
    let amout_of_pids = Math.round(vol / 10);
    let elem_range = all_pids.slice(0, amout_of_pids)
    for (let i = 0; i < all_pids.length; i++) {
      all_pids[i].style.backgroundColor = "#e6e7e8";
    }
    for (let i = 0; i < elem_range.length; i++) {
      elem_range[i].style.backgroundColor = "#69ce2b";
    }
  }
  
  AudioRes() {
    this.audRes = this.audioTestResponse.nativeElement.checked;
    //this.SetProceed();
  }
  
  MicRes() {
    this.micRes = this.micTestResponse.nativeElement.checked;
    //this.SetProceed();
  }

  // SetProceed() {
  //   if (this.audRes && this.micRes && this.volRes) {
  //     document.getElementById("proceedBtn").removeAttribute("disabled");
  //   }
  //   else {
  //     document.getElementById("proceedBtn").setAttribute("disabled", "disabled");
  //   }
  // }

  async startProcteeTestVideo() {
    this.audRes = false;
    this.micRes = false;
    this.volRes = false;
    this.audioPlayEnded = false;
    let stream = await this.proctorService.getVideoStream();
    if (stream) {
      // procteeVideo = document.getElementById("video");
      this.procteeVideo.nativeElement.srcObject = stream;
      this.procteeVideo.nativeElement.play();
      this.procteeVideo.nativeElement.muted = true;
      // document.getElementById("proceedBtn").removeAttribute("disabled");
      this.ShowMediaDivs(true, false);
      $(this.procteeMediaModal.nativeElement).show();
    }
  }
  
  ShowMediaDivs(video: boolean, audio: boolean) {
    // const videoDiv = document.getElementById("procteeVideoDiv");
    // const audioDiv = document.getElementById("procteeAudioDiv");
    this.showVideoTestDiv = video;
    this.showAudioTestDiv = audio;
    // this.procteeVideoDiv.nativeElement.style.display = video ? "block" : "none";
    // this.procteeAudioDiv.nativeElement.style.display = audio ? "block" : "none";
  }
  
  skipMediaTest() {
    if (this.procteeVideo) {
      this.procteeVideo.nativeElement.pause();
    }
    $(this.procteeMediaModal.nativeElement).hide();
    this.ResetMediaValues();
    this.mediaTestStarted = false;
  }
  
  ResetMediaValues() {
    this.micTestResponse.nativeElement.checked = this.micRes = false;
    this.audioTestResponse.nativeElement.checked = this.audRes = false;
    this.volRes = false;
    $(this.micTestResDiv.nativeElement).hide();
    $(this.audioTestResponseDiv.nativeElement).hide();
    $(this.audioPlayer.nativeElement).hide();
  }

  startMediaTest(){
    this.procteeVideo.nativeElement.pause();
    this.ShowMediaDivs(false, true);
    this.startprocteeMicrophoneTest();
    this.mediaTestStarted = true;
  }
  
  async stopProcteeMedia() {//current room is the same as the proctor's username;
    // const audioDiv = document.getElementById("procteeAudioDiv");
    //const proceedBtn = document.getElementById("proceedBtn");
    // if (this.procteeAudioDiv.nativeElement.style.display == "none") {
      
    //   proceedBtn.setAttribute("disabled", "disabled");
    // }
    // else {
      
    // }

    $(this.procteeMediaModal.nativeElement).hide();
    this.ShowMediaDivs(false, false);
    this.ResetMediaValues();

    this.mediaTestStarted = false;
  }

}
