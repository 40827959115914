import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MeetingService } from './meeting.service';
import { environment } from 'src/environments/environment';
import { MeetingHandlerService } from './meeting-handler.service';
import { Subject } from 'rxjs';
import fixWebmDuration from "fix-webm-duration";
import { UtilService } from './util.service';

declare const MediaRecorder: any;

@Injectable({
  providedIn: 'root'
})
export class ProctorService {
  streamchunks = new Map();
  streamChunksArray = [];
  recordingStartTime: number;
  //firstRecordingUploadTime: number;
  //let recordingsno = 0;
  recordingtime = 0;

  uploadstreamchunksinterval;

  chunksuploaded = 0;
  chunkscreated = 0;

  mediaRecorder;

  //publishvideointerval: any;
  examid!: string;
  examdesc!: string;
  company!: string;
  attempt!: number;
  overrideai!: boolean;
  passportversion!: number;
  passporttype!: string;
  aiCheckPhotoBeforeStart: boolean;
  name!: string;
  loggedInUsername!: string;
  passporturl!: string

  private _recording = false;
  private recorderStarted = false;

  videostream;

  recordinguploadstartedinterval;
  examUrl: string;

  videoProducer;
  audioProducer;

  proctorSocketId: string;

  req_headers = new HttpHeaders({ "content-type": "application/json", "accept": "application/json" });
  restartRecordingTimeout: any;

  recorderBps = (480 * 240 * 24 * 1 * 0.07);

  constructor(private meetingService: MeetingService, private meetingHandlerService: MeetingHandlerService, private http: HttpClient, private util: UtilService) {
    this.company = this.getCompany();
    this.company = this.company.toLowerCase();
  }

  destroy() {
    //not sure i need this
    this.proctorSocketId = null;
  }


  async publishVideo() {
    const producingvideo = this.producing('video');
    const producingaudio = this.producing('audio');
    if (this.meetingHandlerService.ready && producingvideo && producingaudio) {
      console.log('start - published video');
      return;
    }

    console.log('stopping publishing in publish video');
    await this.stopPublishing(false);

    let stream = await this.getVideoStream();

    await this.startRecording(stream);

    //const recordingUploadStartedPromise = this.recordingUploadStarted();

    const producers = await Promise.all([this.meetingHandlerService.publishToMediasoupRoom(stream.getVideoTracks()[0], false, undefined, false),
    this.meetingHandlerService.publishToMediasoupRoom(stream.getAudioTracks()[0], false, undefined, false)]);

    this.videoProducer = producers[0];
    this.audioProducer = producers[1];

    console.log('waiting for record upload to start');
    //await recordingUploadStartedPromise;//we will wait until video upload has started before we certify that upload has started. the actual uploading will be triggered from the connectedSubject, which will happen
    console.log('record uploading started');
  }

  async stopPublishing(stopvideostream) {//trackended is a variable i've decided to set when any of the tracks in the video stream ends...if a track has ended, then the stream isn't good anyway and we should stop it so we can create a new one 
    if (this.videostream) {
      let tracks = [...this.videostream.getVideoTracks(), ...this.videostream.getAudioTracks()];
      tracks.forEach(t => {
        try {
          const producer = this.findProducerByTrack(t);
          if (producer) {// && this.meetingHandlerService.ready) {
            this.meetingHandlerService.closeProducer(producer);
          }
          if (stopvideostream || (this.videostream && this.videostream.trackended)) {
            t.stop();
          }
        }
        catch (error) {
          console.log(error);
        }
      });
    }

    if (stopvideostream) {
      this.videostream = null;
      await this.stopRecording();
    }
  }

  findProducerByTrack(track: MediaStreamTrack){
    const producers = [this.meetingHandlerService.videoProducer, this.meetingHandlerService.audioProducer, this.meetingHandlerService.screenShareVideoProducer, this.meetingHandlerService.screenShareAudioProducer];
    for(let i = 0; i < producers.length; i++){
      if(producers[i] && producers[i].track.id == track.id){
        return producers[i];
      }
    }
    return null;
  }

  producing(kind: string) {
    switch (kind) {
      case 'video':
        return this.meetingHandlerService.ready && this.videoProducer && !this.videoProducer.closed;
      case 'audio':
        return this.meetingHandlerService.ready && this.audioProducer && !this.audioProducer.closed;
      default:
        throw new Error('Unexpected producer type');
    }
  }

  recording() {
    return this.mediaRecorder && this.recorderStarted;// this.mediaRecorder.state === 'recording';
  }

  getRecordingMimeType() {
    return MediaRecorder.isTypeSupported('video/webm') ? 'video/webm' : 'video/mp4';
  }

  async startRecording(stream: any, restarting: boolean = false) {
    if (!await this.checkCanUploadInChunks()) {
      throw new Error('Segmented recording not supported');
    }

    const retrievedUploadUrls = new Map<number, string>();

    const retrieveUploadUrl = async (part: number) => {
      if (!retrievedUploadUrls.has(part)) {

        //this url should generate a number of presigned urls
        const numberOfPresignedUrls = 100;
        const generatePresignedUrlEndpoint = `${environment.proctorServiceRootUrl}recording/getpresignedurls?company=${this.company}&examid=${this.examid}&username=${this.loggedInUsername}&time=${this.recordingtime}&partStart=${part}&partEnd=${(part + numberOfPresignedUrls - 1)}&mimeType=${encodeURIComponent(this.getRecordingMimeType())}`;

        const uploadUrls = await this.http.get<any>(generatePresignedUrlEndpoint).toPromise();

        for (const _part in uploadUrls) {
          retrievedUploadUrls.set(parseInt(_part), uploadUrls[_part]);
        }
      }

      if (!retrievedUploadUrls.has(part)) {
        throw new Error(`Upload url for part ${part} not found`);
      }

      const url = retrievedUploadUrls.get(part);
      //retreivedUploadUrls.delete(part);
      return url;
    };

    // const deleteUploadUrl = (part: number) => {
    //   retrievedUploadUrls.delete(part);
    // }

    //const uploadurl = `${environment.proctorServiceRootUrl}recording/uploadpart`
    const uploadStreamChunk = async (part, chunk) => {
      var blob = new Blob([chunk], { 'type': this.getRecordingMimeType() });
      // var fd = new FormData();
      // fd.append('data', blob);

      //const apiUrl = `${uploadurl}?company=${this.company}&examid=${this.examid}&username=${this.loggedInUsername}&time=${this.recordingtime}&part=${part + 1_000_000_000}`;


      try {
        const url = await retrieveUploadUrl(part);

        //await this.http.post(apiUrl, fd).toPromise();

        const headers = new HttpHeaders().set('Content-Type', this.getRecordingMimeType());
        await this.http.put(url, blob, { headers: headers }).toPromise();
        this.chunksuploaded++;
        //deleteUploadUrl(part);
        return true;
      }
      catch (error) {
        console.error(error);
        return false;
      }

    };

    console.log('starting recording');
    await this.stopRecording(restarting);

    //we want to wait until we are sure we have stopped recording (which would make sure all pending uploads are done) before we reset the values below
    this.chunksuploaded = 0;
    this.chunkscreated = 0;
    this.streamchunks = new Map();

    console.log('starting upload interval...');
    this.uploadstreamchunksinterval = setInterval(async () => {
      if (this.streamchunks.size > 0) {
        const part = this.streamchunks.keys().next().value;
        const chunk = this.streamchunks.get(part);
        this.streamchunks.delete(part);
        while (!await uploadStreamChunk(part, chunk)) {
          await this.util.sleep(100);
        }
      }
    }, 10);


    this.recordingtime = new Date().getTime();
    this.mediaRecorder = new MediaRecorder(stream, { mimeType: this.getRecordingMimeType(), bitsPerSecond: this.recorderBps });

    let uploadPart = 1_000_000_000;//we want the partial file names to start high so that the file name lengths will be the same

    this.mediaRecorder.ondataavailable = (e) => {
      if (e.data && e.data.size > 0) {
        const time = new Date().getTime();
        //const time = ++uploadPartId;
        // if (this.streamChunksArray.length == 0) {
        //   this.firstRecordingUploadTime = time;
        // }

        uploadPart++;

        this.streamchunks.set(uploadPart, e.data);
        this.streamChunksArray.push(e.data);

        this.chunkscreated++;
      }
      //if (this.streamchunks.length > 1000) {
      //    const localpartno = new Date().getTime();
      //    uploadthis.streamchunks(localpartno).catch((error) => { console.log('error uploading'); });
      //}
    };
    this.mediaRecorder.onstop = (e) => {
      //const localpartno = new Date().getTime();
      //uploadthis.streamchunks(localpartno).catch((error) => { console.log('error uploading'); });

      //we cannot wait for more than 5 seconds for all the pending stream chunks to be uploaded
      const checkifalluploadsdoneinterval = setInterval(async () => {
        if (this.streamchunks.size === 0) {

          //console.log('upload interval cleared...');
          clearInterval(this.uploadstreamchunksinterval);
          clearInterval(checkifalluploadsdoneinterval);

          //fix header
          if (this.getRecordingMimeType() == 'video/webm') {
            const duration = Date.now() - this.recordingStartTime;

            console.log(`Recording duration is ${duration}`);

            const buggyBlob = new Blob(this.streamChunksArray, { type: this.getRecordingMimeType() });

            const fixedBlob = await fixWebmDuration(buggyBlob, duration, { logger: console.log });

            //check if buggyBlob and fixedBlob are the same size (they are not. fixedBlob is bigger)
            //get numberOfBytesInFirstChunck
            //get the difference in size (sizeDifference) between fixedBlob and buggyBlob
            //numberOfBytesInNewFirstChunk = numberOfBytesInFirstChunk + sizeDifference (as we will assume it is the header that has been made larger)
            //extract numberOfBytesInNewFirstChunk no of bytes from the fixed blob into a fixedHeaderBlob
            //upload the fixedHeaderBlob to the replace the previously uploaded first file in the recording

            const numberOfBytesInFirstChunk = this.streamChunksArray[0].size;
            const sizeDifference = fixedBlob.size - buggyBlob.size;
            const numberOfBytesInNewFirstChunk = numberOfBytesInFirstChunk + sizeDifference;

            const fixedBlobFirstChunk = fixedBlob.slice(0, numberOfBytesInNewFirstChunk, 'application/octet-stream');

            //while (!await uploadStreamChunk(1, fixedBlobFirstChunk));
            while (!await uploadStreamChunk(1_000_000_001, fixedBlobFirstChunk));//we started at 1 billion, so the first chunk will be 1billion + 1
          }
          this.processRecording(this.recordingtime);//don't await this. we want to request and forget. we aren't using the resulting file here. we just want it processed early

          //const fixedBlobFirstChunkUrl = URL.createObjectURL(fixedBlobFirstChunk);

          // const c = document.createElement('a');
          // c.href = fixedBlobFirstChunkUrl;
          // c.download = "fixedHeader";
          // document.body.appendChild(c);
          // c.click();

          this.streamChunksArray = [];

          this._recording = false;

          this.recordingStartTime = 0;
          //this.firstRecordingUploadTime = 0;
        }
      }, 10);
    };

    this.streamchunks = new Map();
    this.streamChunksArray = [];
    this.mediaRecorder.start(10 * 1000);


    this.recordingStartTime = Date.now();
    this._recording = true;
    this.recorderStarted = true;

    //limit recordings to 10 minutes each
    if (this.restartRecordingTimeout) {
      clearTimeout(this.restartRecordingTimeout);
      this.restartRecordingTimeout = null;
    }
    this.restartRecordingTimeout = setTimeout(async () => {
      await this.restartRecording();
    }, 10 * 60 * 1000);//each recording should be 10 minutes long because we don't want to hold too much recording data in memory.
  }

  async checkCanUploadInChunks() {
    const stream = await this.getVideoStream();
    const mediaRecorder = new MediaRecorder(stream, { mimeType: this.getRecordingMimeType(), bitsPerSecond: this.recorderBps });

    let accepted = false;

    let acceptfn: (value: boolean | PromiseLike<boolean>) => void;

    mediaRecorder.ondataavailable = () => {
      if (acceptfn && !accepted) {
        accepted = true;
        mediaRecorder.stop();
        acceptfn(true);
      }
    };

    mediaRecorder.start(10);

    //if 10 seconds have passed, and ondataavailable hasn't fired, then cancel
    this.sleep(10000).then(() => {
      if (acceptfn && !accepted) {
        accepted = true;
        mediaRecorder.stop();
        acceptfn(false);
      }
    });

    return new Promise<boolean>((accept, reject) => {
      acceptfn = accept;
    })
  }

  // recordingUploadStarted() {

  //   return new Promise<void>((accept, reject) => {
  //     let accepted = false;
  //     this.sleep(10000).then(() => {//if in 10 seconds, upload hasn't happened, then reject
  //       if(!accepted){
  //         reject();
  //       }
  //     });
  //     this.recordinguploadstartedinterval = setInterval(() => {
  //       console.log('checking if upload has happened..');
  //       if (this.chunksuploaded > 0) {
  //         clearInterval(this.recordinguploadstartedinterval);
  //         console.log(`${this.chunksuploaded} chunks uploaded`);
  //         accepted = true;
  //         accept();
  //       }
  //       else {
  //         //console.warn('nothing uploaded yet');
  //       }
  //     }, 1000);
  //   });
  // }

  async stopRecording(restarting: boolean = false) {
    if (this.recordinguploadstartedinterval) {
      clearInterval(this.recordinguploadstartedinterval);
    }
    if (this.mediaRecorder && this.mediaRecorder.state === 'recording') {
      console.log('stopping recording...');
      this.mediaRecorder.stop();
      await this.recorderStopped();
    }

    if (!restarting) {
      this.recorderStarted = false;
    }
  }

  async recorderStopped() {
    return new Promise<void>((resolve, reject) => {
      const stoppedinterval = setInterval(() => {
        if (!this._recording) {
          console.log('recording stopped');
          clearInterval(stoppedinterval);
          resolve();
        }
      }, 100);
    });
  }
  1
  async restartRecording() {
    if (this.recorderStarted) {
      await this.stopRecording(true);//TODO: we have to make sure that this._recording is not set to false
      await this.startRecording(await this.getVideoStream(), true);
    }
  }

  async getProctorInfo() {
    const ret = await this.http.get<any>(`${environment.proctorServiceRootUrl}requests/getproctorinfo?examid=${encodeURI(this.examid)}&company=${encodeURI(this.company)}&username=${encodeURI(this.loggedInUsername)}&name=${encodeURI(this.name)}`).toPromise();
    return ret;
  }

  getCompany() {
    const location = window.location.hostname;
    const splitlocation = location.split('.');
    let subdomain_company = "";
    if (splitlocation.length >= 2) {
      subdomain_company = splitlocation[0];
      if (subdomain_company == 'www' || subdomain_company == 'proctor' || subdomain_company == 'proctee') {
        subdomain_company = '';
      }
    }
    else {
      subdomain_company = '';
    }
    return subdomain_company;
  }

  async getExamUrl() {
    let examurlservice = `${environment.proctorServiceRootUrl}requests/getexamurl?company=${this.company}`
    const ret = await this.http.get<any>(examurlservice).toPromise();
    return ret;
  }

  private async getPassportUrl() {
    return await this.http.get<any>(`${environment.proctorServiceRootUrl}requests/getpassporturlbyexamid?examid=${encodeURI(this.examid)}&company=${encodeURI(this.company)}`).toPromise();
  }

  async getPassport(): Promise<Blob> {
    const Url = await this.getPassportUrl();
    return await this.http.get<any>(`${Url.passporturl}${this.loggedInUsername}&company=${encodeURI(this.company.toLowerCase())}&base64=${true}`).toPromise()
      .then(async (data) => {
        const passportBase64 = await fetch(`data:image/jpeg;base64,${data.imgdata}`);
        return await passportBase64.blob();
      })
      .catch((error) => {
        console.log(error);
        return null;
      });
  }

  async getVideoStream() {
    let stream: any;
    console.log("start")
    if (!this.videostream) {
      try {
        stream = await navigator.mediaDevices.getUserMedia({
          video: {
            height: { max: 240 },
            facingMode: 'user'
          },
          audio: true
        });

        [...stream.getAudioTracks(), ...stream.getVideoTracks()].forEach(track => {
          track.onended = () => {
            stream.trackended = true;
          };
        });
        //console.log("1");

      }
      catch (error) {
        //console.log("in catch");
        console.log("error is" + error);

        //if (error.name == "OverconstrainedError" || !error || error==undefined) {
        console.log("in if");

        stream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: 'user'
          },
          audio: true
        });

        [...stream.getAudioTracks(), ...stream.getVideoTracks()].forEach(track => {
          track.onended = () => {
            stream.trackended = true;
          };
        });

        console.log(JSON.stringify(stream));
        console.log("audio time");
        //  }
      }

      // [...stream.getAudioTracks(), ...stream.getVideoTracks()].forEach(track => {
      //   track.onended = () => {
      //     stream.trackended = true;
      //   };
      // });

      this.videostream = stream;
      console.log("video stream");
    }

    return this.videostream;
  }

  async sendCustomPrivateMessageToProctor(messageId: string, data: any) {
    //the room name is always "Proctor_{proctorsocketid}"
    //debugger;
    //const splitroomname = this.meetingHandlerService.currentRoom.split('_');
    //const proctorsocketid = splitroomname[1];
    await this.meetingHandlerService.sendCustomPrivateMessage(messageId, this.proctorSocketId, data);
  }

  async startProctor(requestId: any, company: string) {
    return await this.http.post(`${environment.proctorServiceRootUrl}requests/startproctor`, { requestId, company }, { headers: this.req_headers }).toPromise();
  }

  sleep(milliseconds) {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, milliseconds);
    });
  }

  async aiFlagProctee(reason: string) {
    return this.http.post(environment.proctorServiceRootUrl + `proctees/aiflag`,
      { examid: this.examid, candidateno: this.loggedInUsername, reason: reason, company: this.company, examAttempt: this.attempt },
      { headers: this.req_headers }).toPromise();
  }

  // async getAIFlagConfig(): Promise<any> {
  //   return this.http.get(environment.proctorServiceRootUrl + `proctees/getaiflagconfig?examid=${encodeURI(this.examid)}&company=${encodeURI(this.company)}`).toPromise();
  // }

  async getAiImageAnalysis(imgDataURL, overrideai = this.overrideai) {
    console.log("overrideai is " + overrideai);
    const candidate = { examid: this.examid, company: this.company, username: this.loggedInUsername, attempt: this.attempt, overrideai: overrideai, passportversion: this.passportversion, passporttype: this.passporttype, passporturl: this.passporturl };
    const ret = await this.util.tryPost(`${environment.aiServerUrl}`, { candidate, imgDataURL }, { headers: this.req_headers });
    return ret;
  }

  async processRecording(time) {
    return this.http.post(environment.proctorServiceRootUrl + `recording/processmediafile`,
      { examid: this.examid, candidateno: this.loggedInUsername, company: this.company, time: time },
      { headers: this.req_headers }).toPromise();
  }
}
