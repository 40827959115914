import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  meetingmode: string = "meetingmode";
  focusmode: string = "focusmode";
  defaultphotourl: string = '/assets/user.png';

  constructor(private http: HttpClient) { 
  }

  async runFunctionWithTimeout<T>(fn: () => Promise<T>, timeoutMs: number): Promise<T> {
    const sleepPromise = new Promise<T>(async (resolve, reject) => {
      await this.sleep(timeoutMs);
      reject('timeout');
    });

    const functionPromise = new Promise<T>(async (resolve, reject) => {
      const ret = await fn();
      resolve(ret);
    })

    const ret = await Promise.race([functionPromise, sleepPromise]);

    return ret;
  }

  sleep(milliseconds: number){
    return new Promise((resolve, reject) =>{
      setTimeout(resolve, milliseconds);
    });
  }

  async tryGet(url: string, options: any, maxAttempts = environment.maxRequestRetries): Promise<any>{
    let attempts = 0;
    while(true){
      attempts++;
      try{
        return await this.http.get(url, options).toPromise();
      }
      catch(error){
        if(attempts < maxAttempts && error.status === 0){
          //return await this.getuserexams(candidateno);
          //probably gateway timeout (when status === 0) or seamless scaling error. retry
        }
        else{
          //this.cbtservice.showHttpError(error);
          //this.isloading = false;
          throw error;
        }
      }
    }
  }

  async tryPost(url: string, data: any, options: any, maxAttempts = environment.maxRequestRetries): Promise<any>{
    let attempts = 0;
    while(true){
      attempts++;
      try{
        return await this.http.post(url, data, options).toPromise();
      }
      catch(error){
        if(attempts < maxAttempts && error.status === 0){
          //return await this.submitexam(scheduleid, elapsed);
          //probably gateway timeout (when status === 0) or seamless scaling error. retry
        }
        else{
          //this.cbtservice.showHttpError(error);
          //this.isloading = false;
          throw error;
        }
      }
    }
  }
}
