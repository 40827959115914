import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { utils } from 'protractor';
import { ChatmodalComponent } from './chatmodal/chatmodal.component';
import { ExamiframeComponent } from './examiframe/examiframe.component';
import { LoadingdivComponent } from './loadingdiv/loadingdiv.component';
import { ProcteevideomodalComponent } from './procteevideomodal/procteevideomodal.component';
import { MeetingHandlerService, NotificationMessages } from './services/meeting-handler.service';
import { MeetingService } from './services/meeting.service';
import { ProctorService } from './services/proctor.service';
import { UtilService } from './services/util.service';
import { AIFlags, AIPreventExamStartResponse, AiProctorService } from './services/aiproctor.service';
import { WebcamaccessnotificationdivComponent } from './webcamaccessnotificationdiv/webcamaccessnotificationdiv.component';
import { environment } from 'src/environments/environment';

declare const $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {

  title = 'proctee';
  passportBlob: any;

  started = false;
  @ViewChild('mediaTestModalComponent') mediaTestModalComponent!: ProcteevideomodalComponent;
  @ViewChild('loadingDivComponent') loadingDivComponent!: LoadingdivComponent;
  @ViewChild('examIframeComponent') examIframeComponent!: ExamiframeComponent;
  @ViewChild('chatModalComponent') chatModalComponent!: ChatmodalComponent;
  @ViewChild('webcamAccessNotificationDivComponent') webcamAccessNotificationDivComponent: WebcamaccessnotificationdivComponent;

  seb: boolean = false
  audios = {};//this is an object of audios[socketid][producerid] = new Audio();
  proctorInfo: any;
  prevenstart: AIPreventExamStartResponse;
  showsebpb: boolean = false;

  constructor(private activatedroute: ActivatedRoute, private proctorService: ProctorService, private meetingHandlerService: MeetingHandlerService,
    private aiProctorService: AiProctorService, private meetingService: MeetingService, private toastr: ToastrService, private util: UtilService) {

  }

  async ngAfterViewInit() {
    console.log(`company set to ${this.proctorService.company}`);

    window.addEventListener("message", async (evt) => {
      //console.log(evt.data);
      const messageid = evt.data.messageid;
      if (!messageid) {
        console.error(new Error('messageid not set'));
        return;
      }
      const ret: any = { messageid: messageid };

      try {
        if (evt.data.operation === 'start') {
          console.log('called start - ');
          this.prevenstart = { preventstart: false, reasons: [] };
          await this.proctorService.stopPublishing(false);
          this.aiProctorService.stopAiProctor();

          this.proctorService.destroy();
          await this.meetingHandlerService.destroy();//we want to make sure we reset everything in the meeting service because this could be a second exam the candidate is starting

          //subscribe to meeting events
          this.handleMeetingEvents();//this must happen after resetting the meeting service

          this.proctorService.examid = evt.data.examid;
          this.proctorService.examdesc = evt.data.examdesc;
          this.proctorService.loggedInUsername = evt.data.username;
          this.proctorService.name = evt.data.name;
          this.proctorService.attempt = evt.data.attempt;
          this.proctorService.overrideai = evt.data.overrideai;
          this.proctorService.passportversion = evt.data.passportversion;
          this.proctorService.passporttype = evt.data.passporttype;
          this.proctorService.aiCheckPhotoBeforeStart = evt.data.aiCheckPhotoBeforeStart;
          this.proctorService.passporturl = evt.data.passporturl;

          //get proctor info
          this.proctorInfo = await this.proctorService.getProctorInfo();
          console.log(`proctorInfo: `, this.proctorInfo);


          switch (this.proctorInfo.proctorLevel) {
            case ProctorLevels.RecordOnly:
              await this.recordOnly();
              break;
            case ProctorLevels.AiProctoredOnly:
              await this.aiProctorOnly();
              break;
            case ProctorLevels.LiveProctoredOnly:
              await this.liveProctorOnly(this.proctorService.aiCheckPhotoBeforeStart && this.proctorService.overrideai);
              break;
            case ProctorLevels.LiveProctorWithAiAssist:
              await this.liveProctorWithAiAssist();
              break;
            default:
              throw new Error('Proctor level not supported');
              break;
          }

          if (this.prevenstart?.preventstart) {
            ret.message = "aipreventstart";
            ret.reasons = this.prevenstart.reasons;
          }
        }
        else if (evt.data.operation === 'stop') {

          $(this.chatModalComponent.chatModal.nativeElement).modal('hide');

          this.started = false;

          if (this.proctorInfo && (this.proctorInfo.proctorLevel == ProctorLevels.LiveProctoredOnly || this.proctorInfo.proctorLevel == ProctorLevels.LiveProctorWithAiAssist)) {
            this.meetingHandlerService.leaving = true;
            this.meetingHandlerService.stopConnecting = true;

            try {
              console.log('cbt stop');
              await this.meetingHandlerService.disconnect();
            }
            catch (error) {
              console.error(error);
            }
          }

          try {
            console.log('stopping publishing in stop operation');
            let stopPublishing = true;
            // if(evt.data.reason === 'Not Publishing'){
            //   stopPublishing = false;
            // }
            await this.proctorService.stopPublishing(stopPublishing);
            this.aiProctorService.stopAiProctor();
          }
          catch (error) {
            console.error(error);
          }
          this.aiProctorService.stopAiProctor();
          //ret.status = 'done';
        }
        else if (evt.data.operation === 'checkpublishing') {
          if (this.proctorInfo.proctorLevel == ProctorLevels.LiveProctoredOnly || this.proctorInfo.proctorLevel == ProctorLevels.LiveProctorWithAiAssist) {
            //the minimum requirement should be recording
            ret.publishing = this.proctorService.recording(); //this.proctorService.producing('video') && this.proctorService.producing('audio') && this.proctorService.recording();
          }
          else if (this.proctorInfo.proctorLevel == ProctorLevels.RecordOnly || this.proctorInfo.proctorLevel == ProctorLevels.AiProctoredOnly) {
            ret.publishing = this.proctorService.recording();
          }
          else {
            ret.publishing = false;
          }
        }
        else if (evt.data.operation === 'loading') {
          console.log('loading...');
          // let loadingdiv = document.getElementById('loadingdiv');
          // loadingdiv.style.display = 'block';
          this.loadingDivComponent.loading = true;
        }
        else if (evt.data.operation === 'loaded') {
          console.log('loaded');
          // let loadingdiv = document.getElementById('loadingdiv');
          // loadingdiv.style.display = 'none';
          this.loadingDivComponent.loading = false;
        }
        else if (evt.data.operation === 'procteetestvideo') {
          await this.mediaTestModalComponent.startProcteeTestVideo();
        }
        else if (evt.data.operation === 'raisehand') {
          await this.meetingHandlerService.raiseHand();
        }
        else if (evt.data.operation === 'setcountdowntimer') {
          //'Proctor_' + socket.id
          if ((this.proctorInfo && (this.proctorInfo.proctorLevel == ProctorLevels.LiveProctoredOnly || this.proctorInfo.proctorLevel == ProctorLevels.LiveProctorWithAiAssist)) && this.meetingHandlerService.currentRoom !== this.meetingHandlerService.defaultRoom) {
            //if we are not in the default room, then it means we are in a proctor's room
            this.proctorService.sendCustomPrivateMessageToProctor('setCountdownTimer', { remoteId: this.meetingHandlerService.clientInstanceId, timeleft: evt.data.timeleft });
          }
          //await sendMediasoupControlRequest('setCountdownTimer', { username: socket.id, timeleft: evt.data.timeleft });
        }
        else if (evt.data.operation === 'setproctorpausestatus') {
          if ((this.proctorInfo && (this.proctorInfo.proctorLevel == ProctorLevels.LiveProctoredOnly || this.proctorInfo.proctorLevel == ProctorLevels.LiveProctorWithAiAssist)) && this.meetingHandlerService.currentRoom !== this.meetingHandlerService.defaultRoom) {
            this.proctorService.sendCustomPrivateMessageToProctor('setProctorPauseStatus', { remoteId: this.meetingHandlerService.clientInstanceId, proctorpaused: evt.data.proctorpaused, adminpaused: evt.data.adminpaused, pausereason: evt.data.pausereason });
          }
        }
        else if (evt.data.operation === 'quitseb') {
          this.quitseb();
        }
        else if (evt.data.operation === 'hidesebpb') {
          this.showsebpb = false;
        }
        ret.success = true;
      }
      catch (error) {
        console.error(error);
        ret.success = false;
        ret.message = error?.message ?? error;
      }
      this.examIframeComponent.examIframe.nativeElement.contentWindow.postMessage(ret, '*');
    });

    //this.setCompany();

    $(this.chatModalComponent.chatModal.nativeElement).on('hide.bs.modal', () => {
      console.log('chat closed');
      this.examIframeComponent.examIframe.nativeElement.contentWindow.postMessage({ event: 'chatclosed' }, '*');
    });
    $(this.chatModalComponent.chatModal.nativeElement).on('show.bs.modal', () => {
      console.log('chat opened');
      this.examIframeComponent.examIframe.nativeElement.contentWindow.postMessage({ event: 'chatopened' }, '*');
    });

    await this.loadExamIframe();
  }

  quitseb() {
    window.location.href = environment.quitseburl;
  }

  private async liveProctorWithAiAssist() {
    await this.startLiveProctor(true, true);
  }

  private async liveProctorOnly(aiCheckPhotoBeforeStart: boolean) {
    await this.startLiveProctor(false, aiCheckPhotoBeforeStart);
  }

  private async aiProctorOnly() {
    await this.startRecording();
    await this.startAiProctor(true);
  }

  private async recordOnly() {
    await this.startRecording();
  }

  async startLiveProctor(aiAssist: boolean, aiCheckPhotoBeforeStart: boolean) {
    if (!this.prevenstart?.preventstart) {
      let aiPromise: Promise<void>;
      if (aiAssist) {
        console.log('starting AI Proctor');
        aiPromise = this.startAiProctor(true);
      }
      else if (aiCheckPhotoBeforeStart) {//(!aiAssist){
        //we don't want to check this if we are already doing ai assist because ai proctor part will have done this check 
        console.log('checking for AI to allow start');
        const videoStream = await this.proctorService.getVideoStream() as MediaStream;
        // const videoStream = await this.proctorService.getVideoStream() as MediaStream;
        // await this.startVideo(videoStream);    
        // this.canvas.width = this.video.videoWidth;
        // this.canvas.height = this.video.videoHeight;  
        await this.aiProctorService.startAIProctorVideo(videoStream);
        aiPromise = this.checkAndSetAIPreventStart(true);
      }

      if (!this.proctorInfo.started) {
        await this.proctorService.startProctor(this.proctorInfo.requestId, this.proctorService.company);
      }

      this.meetingHandlerService.meetingid = this.proctorInfo.meetingId;
      this.meetingHandlerService.jwt = this.proctorInfo.meetingJwt;

      //get meeting info
      //const meetinginfo = await this.meetingService.getMeeting(proctorInfo.meetingId).toPromise();
      await this.meetingHandlerService.init();
      this.meetingHandlerService.leaving = false;

      //request server details


      //connect to server until successful


      let passporturl = this.proctorService.passportversion > 0 ? `${environment.s3passportbucketurl}${this.proctorService.company.toLocaleLowerCase()}/passports/${this.proctorService.loggedInUsername}_${this.proctorService.passportversion}.${this.proctorService.passporttype}` : this.proctorService.passporturl;

      console.log('waiting for AI promise');
      await aiPromise;//we want to wait until AI says we can start before we go ahead and start connecting to the live meeting server
      console.log('AI promise has returned');

      if (!this.prevenstart?.preventstart || this.proctorService.overrideai) {
        const domain = await this.meetingHandlerService.getMeetingServer();
        console.log('calling connect to meeting until successful from enterMeeting()');
        const successful = await Promise.race([this.meetingHandlerService.connectToMeetingUntilSuccessful(true, this.meetingHandlerService.defaultRoom, { examid: this.proctorService.examid, passporturl: passporturl }), this.util.sleep(120000)]);

        if (successful === true) {
          console.log('connected successfully');

          //if (!await this.proctorService.producing('video') || !await this.proctorService.producing('audio')) {
          console.log('start - producing');

          //we will call the first publishVideo() from here so that this operation won't return until the publishing has been successful and recording is confirmed
          //subscequent calls to publishVideo will be called from connectedSubject
          //debugger
          await this.proctorService.publishVideo();
          this.started = true;
        }
        else if (successful === false) {
          this.meetingHandlerService.stopConnecting = true;
          this.meetingHandlerService.leaving = true;
          await this.meetingHandlerService.disconnect();
          throw new Error('Unable to connect to proctoring service');
        }
      }
    }
  }

  async startRecording() {
    const videoStream = await this.proctorService.getVideoStream() as MediaStream;
    await this.proctorService.startRecording(videoStream);
  }

  async ngOnInit() {
    const urlParams = new URLSearchParams(window.location.search);
    //debugger
    this.seb = urlParams.has('seb');
    this.showsebpb = this.seb
  }

  private async startAiProctor(checkPreventStart: boolean) {
    const videoStream = await this.proctorService.getVideoStream() as MediaStream;
    if (this.aiProctorService.stopped) {
      await this.aiProctorService.startAiProctor(videoStream);
    }
    const cbtWindow = this.examIframeComponent.examIframe.nativeElement.contentWindow;
    this.aiProctorService.onImageAnalysis.subscribe((analysis) => {
      if (analysis) {
        console.log('image analysis: ', analysis);
        const { brightnessAnalysis, faceAnalysis, penaltyResponse, overrideai } = analysis;

        if (brightnessAnalysis == 'low') {
          cbtWindow.postMessage({ event: 'onLowLight', warning: `The room appears too dark. Please ensure you are in a brightly lit room.`, overrideai: overrideai }, '*');
          // cbtWindow.postMessage({ event: 'onLowLight', warning: `You have been flagged for ${display}`  }, '*');
          if (this.proctorInfo.proctorLevel === ProctorLevels.LiveProctorWithAiAssist) {
            this.meetingHandlerService.sendCustomMessage("lowLight", { reason: "Low light detected" });
          }
        }
        // else if (brightnessAnalysis == 'high') {
        //   cbtWindow.postMessage({ event: 'onHighIntensityLight', warning: `The light behind you is too bright. Please ensure you are not backing the light`,overrideai:overrideai }, '*');
        //   // cbtWindow.postMessage({ event: 'onHighIntensityLight', warning: `You have been flagged for ${display}` }, '*');
        //   if(this.proctorInfo.proctorLevel === ProctorLevels.LiveProctorWithAiAssist){
        //     this.meetingHandlerService.sendCustomMessage("highIntensityLight", { reason: "High intensity light detected" });
        //   }
        // }

        if (faceAnalysis.noFace) {
          cbtWindow.postMessage({ event: 'onNoFace', warning: `We can't see your face. Please ensure you are facing your camera, and that you are in a brightly lit room. Also ensure you are not backing the light or an open window`, overrideai: overrideai }, '*');
          // cbtWindow.postMessage({ event: 'onNoFace', warning: `You have been flagged for ${display} `}, '*');
          if (this.proctorInfo.proctorLevel === ProctorLevels.LiveProctorWithAiAssist) {
            this.meetingHandlerService.sendCustomMessage("noFace", { reason: "No face detected" });
          }
        }
        else {
          if (faceAnalysis.multipleFaces) {
            cbtWindow.postMessage({ event: 'onMultipleFaces', warning: `We can see multiple people. Please ensure you are the only one in the room while taking the exam`, overrideai: overrideai }, '*');
            // cbtWindow.postMessage({ event: 'onMultipleFaces', warning: `You have been flagged for ${display} ` }, '*');
            if (this.proctorInfo.proctorLevel === ProctorLevels.LiveProctorWithAiAssist) {
              this.meetingHandlerService.sendCustomMessage("multipleFaces", { reason: "Multiple faces detected" });
            }
          }
          if (faceAnalysis.candidateFaceIsNotAMatch) {
            cbtWindow.postMessage({ event: 'onCandidateFaceMatchChange', warning: `We can't see you clearly. Please ensure you are facing your camera, and that you are in a brightly lit room. Also ensure that you are using a recent picture.`, overrideai: overrideai }, '*');
            // cbtWindow.postMessage({ event: 'onCandidateFaceMatchChange', warning: `You have been flagged for ${display} ` }, '*');
            if (this.proctorInfo.proctorLevel === ProctorLevels.LiveProctorWithAiAssist) {
              this.meetingHandlerService.sendCustomMessage("candidateFaceMismatch", { reason: "Candidate's face does not match the face on record" });
            }
          }
        }

        if (penaltyResponse?.length > 0) {
          this.sendpenaltynotification(penaltyResponse, cbtWindow)
        }
      }
    });

    this.aiProctorService.onNoiseAnalysis.subscribe((noiseLevel) => {
      //cbtWindow.postMessage({ event: 'onNoise', warning: `The room appears to be noisy. Please ensure you are in a quiet room.` }, '*');
      if (this.proctorInfo.proctorLevel === ProctorLevels.LiveProctorWithAiAssist) {
        this.meetingHandlerService.sendCustomMessage("noise", { reason: "Noise detected" });
      }
      this.proctorService.aiFlagProctee(AIFlags.Noise);
    })

    if (checkPreventStart) {
      await this.checkAndSetAIPreventStart(false);
    }
  }

  private async checkAndSetAIPreventStart(force: boolean) {
    const prevenstart = await this.aiProctorService.aiPreventExamStart(force);

    //console.log('prevent start: ', prevenstart);

    if (prevenstart.preventstart) {
      this.prevenstart = prevenstart;
    }
  }

  private async sendpenaltynotification(penaltyresponse: any[], cbtWindow: any) {
    penaltyresponse.forEach(penalty => {
      if (penalty.penalized) {
        cbtWindow.postMessage({ event: 'aipenalty', message: this.getPenaltyMessage(penalty.penalty, penalty.reason) }, '*');
      }
    });
  }

  private getPenaltyMessage(penalty: string, reason: string) {
    let reasonMsg = '';
    let penaltyMsg = '';
    switch (reason) {
      case 'NoFace':
        reasonMsg = `'No face detected'`;
        break;
      case 'MultipleFaces':
        reasonMsg = `'Multiple faces detected'`;
        break;
      case 'LowLight':
        reasonMsg = `'Low light detected'`;
        break;
      case 'HighIntensity':
        reasonMsg = `'High intensity light detected'`;
        break;
      case 'FaceMismatch':
        reasonMsg = `'Face mis-match detected'`;
        break;
    }

    switch (penalty) {
      case 'Restart':
        penaltyMsg = `Your exam has been restarted because ${reasonMsg}`;
        break;
      case 'ReduceTime':
        penaltyMsg = `Your time has been reduced because ${reasonMsg}`;
        break;
      case 'EndExam':
        penaltyMsg = `Your exam has been stopped because ${reasonMsg}`;
        break;
    }

    return penaltyMsg;
  }

  private handleMeetingEvents() {
    this.meetingHandlerService.participantProducerRemovedSubject.subscribe(params => {
      const { socketId, producerId, kind } = params;
      if (kind === 'audio') {
        if (this.audios[socketId]) {
          delete this.audios[socketId][producerId];
          if (Object.keys(this.audios[socketId]).length == 0) {
            delete this.audios[socketId];
          }
        }
      }
    });
    //this.meetingHandlerService.screenSharePublishedSubject.subscribe(() => this.resized());
    this.meetingHandlerService.participantAddedSubject.subscribe(() => {
      //not needed
    });
    this.meetingHandlerService.participantRemovedSubject.subscribe(async (socketid: string) => {
      //if the candidate is in a proctor's room, and that proctor leaves, candidate should go to the default room

    });
    this.meetingHandlerService.participantBeingRemovedSubject.subscribe(async (socketid: string) => {
      console.log(this.meetingHandlerService.participantsMap.get(socketid));
      if (this.meetingHandlerService.currentRoom != this.meetingHandlerService.defaultRoom && this.meetingHandlerService.participantsMap.has(socketid) && this.meetingHandlerService.participantsMap.get(socketid).host) {
        await this.meetingHandlerService.moveParticipantsToRoom(this.meetingHandlerService.defaultRoom, [this.meetingHandlerService.meParticipant.username]);
        this.proctorService.proctorSocketId = '';
      }
    });
    this.meetingHandlerService.meetingEndedSubject.subscribe(() => {
    });
    this.meetingHandlerService.producerTypeUnavailableSubject.subscribe(() => {
      //not needed
    });
    this.meetingHandlerService.takeAttendanceSubject.subscribe(() => {
      //not needed
    });
    this.meetingHandlerService.reconnectingSubject.subscribe(async () => {
      //not needed
    });
    this.meetingHandlerService.reconnectedSubject.subscribe(() => {
      //not needed
    });
    this.meetingHandlerService.disconnectedSubject.subscribe(() => {
      console.log('stopping publishing in disconnect subject');
      this.chatModalComponent.chat = [];
      this.proctorService.stopPublishing(false);
      this.aiProctorService.stopAiProctor();
      this.proctorService.proctorSocketId = '';
      //if we're reconnecting, reconnect to the default room
      this.meetingHandlerService.currentRoom = this.meetingHandlerService.defaultRoom;
      this.roomChangeNotification();
    });
    // this.meetingHandlerService.toastrError.subscribe(message => {
    //   this.toastr.error(message);
    // });
    // this.meetingHandlerService.toastrWarning.subscribe(message => {
    //   this.toastr.warning(message);
    // });
    // this.meetingHandlerService.toastrSuccess.subscribe(message => {
    //   this.toastr.success(message);
    // });
    this.meetingHandlerService.notifySubject.subscribe((message) => {
      console.log('notification: ', message);
      const messageid = message.messageid;
      const data = message.data;

      switch (messageid) {
        case NotificationMessages.microphonedisabledbyhost:

          break;
        case NotificationMessages.notyetenabledtospeak:

          break;
        case NotificationMessages.webcamdisabledbyhost:

          break;
        case NotificationMessages.enabledtospeak:

          break;
        case NotificationMessages.enteredroom:

          break;
        case NotificationMessages.brokenout:

          break;
        case NotificationMessages.errorproducing:

          break;
        default:
          this.toastr.warning(`Unknown message id ${messageid}`);
      }
    });
    this.meetingHandlerService.participantHandRaisedSubject.subscribe(participant => {
    });
    this.meetingHandlerService.enteredRoomSubject.subscribe(() => {
      this.roomChangeNotification();
    });
    this.meetingHandlerService.connectedSubject.subscribe(async (first: boolean) => {
      console.log('connected subject handler. first: ' + first);
      if (!first) {
        await this.proctorService.publishVideo();
        //if we have just reconnected, start the ai proctor, but don't check for prevent start        
      }
      if (this.proctorInfo.proctorLevel == ProctorLevels.LiveProctorWithAiAssist) {
        await this.startAiProctor(first);
      }
    });
    this.meetingHandlerService.handDroppedSubject.subscribe((message) => {
      const socketId = message.socketId;
      const remoteId = message.remoteId; //the user who dropped the hand
      const unlocked = message.unlocked;
      const timeout = message.timeout;

      // if(socketId == this.meetingHandlerService.socket.id){
      //   this.meetingHandlerService.handraised = false;
      //   if(timeout || (socketId != remoteId && !unlocked)){
      //     //if the user didn't drop his own hand, and it wasn't dropped as a result of unlocking then notify him
      //     this.toastr.warning('Hand Dropped');
      //   }
      //   else if(unlocked && this.meetingHandlerService.meParticipant.unlocked){
      //     //if the user had raised his hand and was then unlocked by the host, he should immediately start sending audio
      //     this.settings.enableMicrophone = true;
      //     this.meetingHandlerService.publishAudio(false);
      //   }
      // }
      // else{
      //   if(this.meetingHandlerService.participantsMap.has(socketId)){
      //     const participant = this.meetingHandlerService.participantsMap.get(socketId);
      //     participant.handRaised = false;
      //   }
      // }
    });
    // this.meetingHandlerService.remoteProducerPausedSubject.subscribe((message) => {
    // });
    // this.meetingHandlerService.remoteProducerResumedSubject.subscribe((message) => {
    // });
    this.meetingHandlerService.videoConsumerRemovedSubject.subscribe((socketId) => {
    });
    this.meetingHandlerService.audioConsumerRemovedSubject.subscribe((socketId) => {
    });

    this.meetingHandlerService.screenShareVideoProducerAddedSubject.subscribe(async (data: any) => {
      // const {socketid, producerid, kind} = data;
      // if(this.meetingHandlerService.screenShareConsumer && this.previousScreenShareParticipantSocketId){
      //   await this.meetingHandlerService.closeConsumer(this.previousScreenShareParticipantSocketId, this.meetingHandlerService.screenShareConsumer);
      //   this.previousScreenShareParticipantSocketId = socketid;
      // }
      // const participant = this.meetingHandlerService.participantsMap.get(socketid);
      // this.meetingHandlerService.screenShareConsumer = (await this.meetingHandlerService.createConsumer(socketid, producerid, kind)).consumer;
      // this.meetingHandlerService.setPreferredConsumerSpatialLayer(socketid, this.meetingHandlerService.screenShareConsumer.id, 1);
      // this.meetingHandlerService.screenShareParticipantSocketId = socketid;
      // this.meetingHandlerService.screenShareParticipantUsername = participant.username;
      // this.meetingHandlerService.screenShareParticipantName = participant.name;
      // this.meetingHandlerService.participantScreenShareAvailable = true;
      // this.meetingHandlerService.focusedMode = true;
      // this.meetingHandlerService.resumeConsumer(participant.socketid, this.meetingHandlerService.screenShareConsumer, false);
      // this.resized();
      // this.screensharevideo.nativeElement.srcObject = new MediaStream([this.meetingHandlerService.screenShareConsumer.track]);
    });

    this.meetingHandlerService.remoteAudioProducerAddedSubject.subscribe(async (data: { socketid: string; producerid: string; kind: string; private: boolean; }) => {
      const participant = this.meetingHandlerService.findParticipant(data.socketid);
      if (participant && participant.host) { //for proctoring, we are only interested in the proctor's audio stream. ideally, we don't even want to be notified of the other candidates' streams
        const createConsumerInfo = await this.meetingHandlerService.createConsumer(data.socketid, data.producerid, 'audio', false);
        const audioConsumer = createConsumerInfo.consumer;

        const audio = new Audio();
        audio.autoplay = true;
        audio.srcObject = new MediaStream([audioConsumer.track]);

        if (!this.audios[data.socketid]) {
          this.audios[data.socketid] = {};
        }
        this.audios[data.socketid][data.producerid] = audio;
      }
    });
    this.meetingHandlerService.remoteVideoProducerAddedSubject.subscribe(async (data) => {
      const { socketid, producerid } = data;
    });
    this.meetingHandlerService.customMessageSubject.subscribe(async (data) => {
      switch (data.messageId) {
        case 'privateChatMessage':
          this.chatModalComponent.addChatMessage(data.message.message, false, data.private);
          this.examIframeComponent.examIframe.nativeElement.contentWindow.postMessage({ event: 'chatopened' }, '*');
          $(this.chatModalComponent.chatModal.nativeElement).modal('show');
          this.chatModalComponent.chatOpened = true;
          break;
        case 'privateChatClosed':
          //doesn't make sense to close the chat modal when the proctor closes his own...rather, the text area will be disabled
          this.chatModalComponent.chatOpened = false;
          this.chatModalComponent.chattextarea.nativeElement.value = '';
          this.chatModalComponent.addChatMessage('&lt;&lt;Chat Ended. Please close chat to proceed&gt;&gt;', false, true);
          break;
        case 'proctorSocketId':
          this.proctorService.proctorSocketId = data.message.proctorSocketId;
          break;
        case 'restartPublish':
          await this.proctorService.stopPublishing(true);
          this.aiProctorService.stopAiProctor();
          await this.util.sleep(2000);//wait for 2 seconds so that the webcam isn't overwhelmed
          await this.proctorService.publishVideo();
          //if we have just republishing, start the ai proctor, but don't check for prevent start
          if (this.proctorInfo.proctorLevel == ProctorLevels.LiveProctorWithAiAssist) {
            await this.startAiProctor(false);
          }
          break;
        default:

      }
    });
  }

  async loadExamIframe() {
    if (this.proctorService.company) {
      try {
        const examurl = await this.proctorService.getExamUrl();

        if (examurl && examurl.url) {
          try {
            await this.proctorService.getVideoStream();
          }
          catch (error) {
            // console.log(JSON.stringify(error))
            //console.log("error is" + error);

            alert('Unable to acquire video stream.');
            return;
          }


          const urlParams = new URLSearchParams(window.location.search);
          //debugger
          let passthroughquery = '';
          let seb = '';
          if (urlParams.has('passthroughquery')) {
            passthroughquery = urlParams.get('passthroughquery');
          }
          if (urlParams.has('seb')) {
            seb = urlParams.get('seb');
          }
          let iframeurl = examurl.url;

          if (passthroughquery) {
            if (!iframeurl.includes('?')) {
              iframeurl += '?';
            }
            else {
              iframeurl += '&';
            }
            iframeurl += passthroughquery;
          }
          if (seb) {
            if (!iframeurl.includes('?')) {
              iframeurl += '?';
            }
            else {
              iframeurl += '&';
            }
            iframeurl += "seb=" + seb;
          }
          this.proctorService.examUrl = iframeurl;

          this.examIframeComponent.examIframe.nativeElement.src = iframeurl;

          this.examIframeComponent.examIframe.nativeElement.style.display = 'block';
          this.webcamAccessNotificationDivComponent.webcamAccessNotificationDiv.nativeElement.style.display = 'none';

        }
        else {
          console.log('No exam url found. Please contact the system administrator');
          alert('No exam url found. Please contact the system administrator');
          //examiframe.src = 'https://cbt.webtest.ng';
        }
      }
      catch (error) {
        console.error(error);
        alert('Error setting exam url');
      }
    }
    else {
      console.error('company not set');
      this.toastr.error('company not set');
      //alert('company not set');
    }
  }

  roomChangeNotification() {
    if (this.meetingHandlerService.currentRoom !== "Main") {//if current room != default room  
      this.examIframeComponent.examIframe.nativeElement.contentWindow.postMessage({ event: 'enteredroom' }, '*');
    }
    else {
      this.examIframeComponent.examIframe.nativeElement.contentWindow.postMessage({ event: 'leftroom' }, '*');
    }
  }
}


export enum ProctorLevels {
  RecordOnly = 'RecordOnly',
  AiProctoredOnly = 'AiProctoredOnly',
  LiveProctoredOnly = 'LiveProctoredOnly',
  LiveProctorWithAiAssist = 'LiveProctorWithAiAssist'
} 
