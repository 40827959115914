import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-loadingdiv',
  templateUrl: './loadingdiv.component.html',
  styleUrls: ['./loadingdiv.component.css']
})
export class LoadingdivComponent implements OnInit {

  @ViewChild('loadingdiv') loadingdiv!: ElementRef;
  loading: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
