import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProctorService } from '../services/proctor.service';

@Injectable()
export class SubdomainInterceptorInterceptor implements HttpInterceptor {

  constructor(public proctorservice:ProctorService) {}
  intercept(request : HttpRequest<any>,next:HttpHandler) : Observable<HttpEvent<any>>{   
    const company = this.proctorservice.getCompany();
    console.log("got here company is"+ company)
    request = request.clone({
        setHeaders : {
            company_subdomain: company
        }            
      });      
  return next.handle(request);
 
}
}
