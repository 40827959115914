export const environment = {
  production: true,
  meetingServiceRootUrl: 'https://api.vcircle.ng/',
  proctorServiceRootUrl: '//proctorservices.webtest.ng/',
  mediasoupScheme: 'https',
  aiServerUrl: 'https://aiproctor.webtest.ng/',
  s3passportbucketurl :"https://cbtpassportstorage.s3.us-west-2.amazonaws.com/",
  quitseburl: "https://cbt.webtest.ng/quitseb",
  maxRequestRetries: 3
};
