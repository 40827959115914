import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MeetingDomain, MeetingLite } from '../models/Meeting'

@Injectable({
  providedIn: 'root'
})
export class MeetingService {
  readonly rootURL = environment.meetingServiceRootUrl;
  req_headers =  new HttpHeaders({"content-type": "application/json", "accept" : "application/json"});
  constructor(private http: HttpClient) { }
  // setLookingForServer(meetingid: string, jwt: string) {
  //   return this.http.post(`${this.rootURL}meeting/setlookingforserver`, {meetingid, jwt});
  // }
  // incrementUsers(meetingid: string, jwt: string) {
  //   return this.http.post(`${this.rootURL}meeting/incrementusers`, {meetingid, jwt});
  // }

  // decrementUsers(meetingid: string, jwt: string) {
  //   return this.http.post(`${this.rootURL}meeting/decrementusers`, {meetingid, jwt});
  // }

  // getMeetingSubdomains(meetingid: string) {
  //   return this.http.get<MeetingDomain[]>(`${this.rootURL}meeting/getmeetingsubdomains?meetingid=${meetingid}`);
  // }

  requestMeetingServer(meetingid: string, jwt: string, clientinstanceid: string, newServer: boolean){
    return this.http.post<MeetingDomain>(`${this.rootURL}meeting/requestmeetingserver`, {meetingid, jwt, clientinstanceid, newServer});
  }

  deassignMeetingServer(meetingid: string, jwt: string, clientinstanceid: string){
    //return this.http.post<MeetingDomain>(`${this.rootURL}meeting/deassignmeetingserver`, {meetingid, jwt, clientinstanceid});
    const data: any = {meetingid, jwt, clientinstanceid};
    const datastring = JSON.stringify(data);
    
    navigator.sendBeacon(`${this.rootURL}meeting/deassignmeetingserver`, datastring);
  }

  selfDisconnectAndDeassign(domain: MeetingDomain, jwt: string, clientInstanceId: string){
    //it is expected that a user will only disconnect and deassign when he is closing the page or when a new clientinstanceid has been generated
    try{
      const data: any = {jwttoken: jwt, socketid: clientInstanceId};
      const datastring = JSON.stringify(data);
      if(domain){
        navigator.sendBeacon(`${environment.mediasoupScheme}://${domain.domain}:${domain.port}/service/self_disconnect_client`, datastring);
      }
    }
    catch(error){
      console.error(error);
    }

  } 

  // async confirmRecorderReady(meetingid: string) {
  //   //the service will return true if recorder was never created.
  //   //if the recorder was created, then it checks if the browser has connected to the media server
  //   const readiness = await this.http.get<any>(this.rootURL + `meeting/requiredrecorderready?meetingid=${meetingid}`).toPromise();
  //   console.log('recorder ready: ', readiness);
  //   return readiness;
  // }

  startMeeting(meetingid: string, jwt: string, startParticipantsMuted: boolean, takeAttendance:boolean, attendanceInterval:number, numberOfParticipants: number, allowParticipantsRecord: boolean): Observable<any> {
    return this.http.post(`${this.rootURL}meeting/start`, { meetingid: meetingid, jwt: jwt, startParticipantsMuted: startParticipantsMuted, takeAttendance: takeAttendance, attendanceInterval: attendanceInterval, numberOfParticipants: numberOfParticipants, allowNonHostsRecord: allowParticipantsRecord}, { headers: { 'Content-Type': 'application/json' } });
  }

  // recordMeeting(meetingid: string, jwt: string){
  //   return this.http.post(`${this.rootURL}meeting/startrecord`, { meetingid: meetingid, jwt: jwt }, { headers: { 'Content-Type': 'application/json' } });
  // }

  endMeeting(meetingid: string, jwt: string): Observable<any>{
    return this.http.post(`${this.rootURL}meeting/stop`, { meetingid: meetingid, jwt: jwt }, { headers: { 'Content-Type': 'application/json' } });
  }

  async producing(domain: MeetingDomain, username: string, kind: string) {
    const data = await this.http.get<any>(`${environment.mediasoupScheme}://${domain.domain}:${domain.port}/service/producing?username=${username}&kind=${kind}`).toPromise();

    return data.producing;

    // for(let i = 0; i < domains.length; i++){
    //   const promise = this.http.get(`${environment.mediasoupScheme}://${domains[i].domain}:${domains[i].port}/service/producing?username=${username}`).toPromise();
    //   confirmationpromises.push(promise);
    // }

    // const anyPromise = new Promise(function(accept: any, reject: any){
    //   let rejectedPromiseCount = 0;
    //   confirmationpromises.forEach(function(promise, index){
    //     promise.then(() => {
    //       accept();
    //     }).catch(() => {
    //       rejectedPromiseCount++;
    //       if(rejectedPromiseCount == confirmationpromises.length){
    //         reject();
    //       }
    //     });
    //   });
    // });

    // await anyPromise;

    //const myPromiseClass: any = Promise;//because this version of typescript doesn't have Promise.any()
    //await myPromiseClass.any(confirmationpromises);
    
    //console.log('options: ', options);
    //console.log('producing: ', producing);
  }

  getMeeting(meetingid: string): Observable<MeetingLite>{
    return this.http.get<MeetingLite>(this.rootURL + `meeting/getinfolite?meetingid=${meetingid}`);
  }

  decodeJWT(meetingid: string, jwt: string){
    return this.http.get(`${this.rootURL}meeting/decodeusertoken?meetingid=${meetingid}&jwt=${jwt}`);
  }

  setMuteAllParticipants(meetingid: string, jwt: string){
    return this.http.post(`${this.rootURL}meeting/setmuteallparticipants`, {meetingid: meetingid, jwt: jwt});
  }

  login(username: string, password:string){
    return this.http.post(`${this.rootURL}home/login`, { username: username, password: password}, { headers: { 'Content-Type': 'application/json' } });
    //return await this.http.post(`${this.rootURL}home/login`, { username: username, password: password }, { headers: { 'Content-Type': 'application/json' } });
  }

  getCompany(jwt: string){
    return this.http.post(`${this.rootURL}home/getcompanyinfo`, { jwt: jwt }, { headers: { 'Content-Type': 'application/json' } });
  }

  createMeeting(jwt:string, title:string, mode:string, publicaccess:boolean, starttime:any){
    return this.http.post(`${this.rootURL}meeting/vcirclecreate`, { jwt: jwt, title:title, mode:mode, allowpublicaccess:publicaccess, scheduledStartTime: starttime}, { headers: { 'Content-Type': 'application/json' } });
  }

  getMeetingsLite(jwt: string){
    return this.http.get<MeetingLite>(this.rootURL + `meeting/vcirlcelistlite?jwt=${jwt}`);  
  }

  getUserToken(meetingid: string, jwt:string){
    return this.http.get(this.rootURL + `meeting/vcirclecreateusertoken?meetingid=${meetingid}&jwt=${jwt}`); 
  }

  getPublicUserToken(meetingid: string, username:string, name: string){
    return this.http.get(this.rootURL + `meeting/vcirclecreatepublicusertoken?meetingid=${meetingid}&username=${encodeURI(username)}&name=${encodeURI(name)}`); 
  }

  userLoggedIn(){
    const loggedin = window.localStorage.getItem(`vCirlce-User`) != null;
    return loggedin;
  }
}
