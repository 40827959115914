<div id="procteeMediaModal" #procteeMediaModal class="modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Media Test</h5>
            </div>
            <div class="modal-body" style="max-height: 70vh; overflow: auto">
                <div #procteeVideoDiv [ngStyle]="{'display': this.showVideoTestDiv ? 'block' : 'none'}">
                    <video #procteeVideo class="proctee-video circle" playsinline muted>Video stream not available.</video>
                    <p class="text-center">
                        Please adjust your webcam or sitting position to ensure your face is showing inside the circle above before you proceed with the examination.
                    </p>
                    <p class="text-center text-danger">
                        ENSURE YOUR FACE IS <strong>CLEARLY VISIBLE</strong> BEFORE YOU PROCEED WITH YOUR EXAMINATION
                    </p>
                    <p class="text-center text-warning">
                        If your face is not visible, please reconfigure your webcam, restart your computer or use a different computer. <br /><b>Failure to comply may result in disqualification</b>
                    </p>
                </div>
                <div #procteeAudioDiv [ngStyle]="{'display': this.showAudioTestDiv ? 'block' : 'none'}">
                    <div class="">
                        <h4>Microphone Test</h4>                        
                        <div class="pids-wrapper my-4">
                            <div class="pid"></div>
                            <div class="pid"></div>
                            <div class="pid"></div>
                            <div class="pid"></div>
                            <div class="pid"></div>
                            <div class="pid"></div>
                            <div class="pid"></div>
                            <div class="pid"></div>
                            <div class="pid"></div>
                            <div class="pid"></div>
                        </div>

                        <p>
                            Click on the <strong>Test Microphone</strong> button and say your name out loud. Play the resulting audio file and confirm you can hear your name.
                        </p>
                        <button #micTestBtn class="btn btn-sm btn-primary" (click)="recordAudio()" title="Click and say your name out loud" [disabled]="this.recording">Test Microphone</button>
                        
                        <div class="my-2">
                            <audio #micTestAudio [ngStyle]="{'display': this.recorded ? 'block' : 'none'}" controls></audio>
                        </div>

                        <div class="form-group">                           
                            <div class="form-check mt-2" #micTestResponseDiv [ngStyle]="{'display': this.audioPlayEnded ? 'block' : 'none'}">
                                <input class="form-check-input" (change)="MicRes()" type="checkbox" id="micTestResponseCheckbox" #micTestResponse />
                                <label class="form-check-label" for="micTestResponseCheckbox"> Can you hear your name clearly?</label>
                            </div>
                        </div>
                    </div>
                    
                    <div class="mt-4">
                        <h4>Audio Test</h4>
                        <button class="btn btn-sm btn-primary" (click)="startProcteeTestAudio()">Test Audio</button>
                        <audio #procteeAudio [ngStyle]="{'display': this.recording ? 'none' : (!this.recording && this.recorded ? 'block' : 'none')}">
                            <source src="../../assets/beep.mp3" type="audio/mp3"/>
                        </audio>
                        <div class="form-group">
                            <div class="form-check mt-2" style="display: none;" #audioTestResponseDiv>
                                <input class="form-check-input" (change)="AudioRes()" type="checkbox" id="audioTestResponseCheckbox" #audioTestResponse />
                                <label class="form-check-label" for="audioTestResponseCheckbox"> Did you hear a beep? </label>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
            </div>
            <div class="d-flex justify-content-between modal-footer">
                <button type="button" class="btn btn-danger" (click)="skipMediaTest()" data-bs-dismiss="procteeVideoModal">Skip Media Test</button>
                <button *ngIf="!this.mediaTestStarted" #startBtn type="button" class="btn btn-warning" (click)="startMediaTest()" data-bs-dismiss="procteeVideoModal">Proceed</button>
                <button *ngIf="this.mediaTestStarted" #finishBtn type="button" class="btn btn-warning" (click)="stopProcteeMedia()" data-bs-dismiss="procteeVideoModal" [disabled]="!(this.audRes && this.micRes && this.volRes)">Proceed</button>
            </div>
        </div>
    </div>
</div>
