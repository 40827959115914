import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ProctorService } from '../services/proctor.service';

declare const $: any;

@Component({
  selector: 'app-chatmodal',
  templateUrl: './chatmodal.component.html',
  styleUrls: ['./chatmodal.component.css']
})
export class ChatmodalComponent implements OnInit {

  @ViewChild('chatModal') chatModal!: ElementRef;
  @ViewChild('chatModalBody') chatModalBody: ElementRef;

  constructor(private proctorService: ProctorService) { }

  @ViewChild('chattextarea') chattextarea: ElementRef;
  chatOpened: boolean = false;

  chat: {message: string, local: boolean, privateMessage: boolean}[] = [];

  ngOnInit(): void {
  }

  async sendChatMessage(event) {//current room is the same as the proctor's username;
    if(event){
      event.preventDefault();
    }
    if (this.chattextarea.nativeElement.value) {
      const message = { message: this.chattextarea.nativeElement.value };
      await this.proctorService.sendCustomPrivateMessageToProctor('privateChatMessage', message);
      this.chattextarea.nativeElement.value = '';
      this.addChatMessage(message.message, true, true);
    }
  }

  addChatMessage(message: string, local: boolean, privateMessage: boolean) {

    this.chat.push({message, local, privateMessage});

    this.scrollChatToBottom();
  }

  scrollChatToBottom() {
    $(this.chatModalBody.nativeElement).animate({ scrollTop: $('.chat-modal-chat-inner').height() }, 500);
  }

}
