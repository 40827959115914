import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-webcamaccessnotificationdiv',
  templateUrl: './webcamaccessnotificationdiv.component.html',
  styleUrls: ['./webcamaccessnotificationdiv.component.css']
})
export class WebcamaccessnotificationdivComponent implements OnInit {

  @ViewChild('webcamAccessNotificationDiv') webcamAccessNotificationDiv: ElementRef;
  constructor() { }

  ngOnInit(): void {
  }

}
